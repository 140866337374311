import classNames from "classnames";
import React, { FC, useEffect, useState } from "react";
import { HiEllipsisVertical, HiOutlineBars3 } from "react-icons/hi2";
import { toast } from "react-toastify";

import { AvatarPlaceholder } from "../../../../../../components/avatar/AvatarPlaceholder";
import Button from "../../../../../../components/button/Button";
import { Dropdown } from "../../../../../../components/dropdown/new-dropdown/Dropdown";
import ReassignedSvg from "../../../../../../components/svg/ReassignedSvg";
import { getInitials } from "../../../../../../constants/constants";
import { WorkTaskModel } from "../../../../../../factories/work/model/work-task.model";
import {
  useDeleteWorkTaskMutation,
  useReassignWorkTaskMutation,
  useUpdateWorkTaskMutation,
  useUpdateWorkTaskStatusMutation,
} from "../../../../../../slices/WorkTasksSlice";
import { IUser } from "../../../../../../types/types";
import { WorkTaskModal } from "../../../../modals/WorkTaskModel";
import { useAppSelector } from "../../../../../../redux/redux";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import { Controller, useForm } from "react-hook-form";
import { StyledDatePicker } from "../../../../../../components/datepicker/datepicker";

type Props = {
  workTask: WorkTaskModel;
  users: IUser[];
};
type FormData = {
  name: string;
  description: string;
  dueDate: string;
  startDate: string;
};
export const WorkTask: FC<Props> = ({ workTask, users }) => {
  const { user } = useAppSelector((state) => state.appReducer);
  const { workId } = useParams();

  const [updateWorkTaskStatus, { isSuccess, isError, data: updatedWorkTask }] =
    useUpdateWorkTaskStatusMutation();
  const [reassignWorkTask] = useReassignWorkTaskMutation();
  const [deleteWorkTask] = useDeleteWorkTaskMutation();
  const [updateContactNote] = useUpdateWorkTaskMutation();

  const [previewIsDone, setPreviewDone] = useState<boolean | null>(null);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleChangeWorkTaskStatus = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPreviewDone(e.target.checked);
    updateWorkTaskStatus({
      taskId: workTask?.id,
      isDone: e.target.checked,
    });
  };
  const { control, getValues, setValue } = useForm<FormData>({
    defaultValues: {
      dueDate: moment(workTask?.dueDate).isValid()
        ? moment(workTask?.dueDate).toISOString()
        : moment().toISOString(),
    },
  });

  useEffect(() => {
    setValue(
      "dueDate",
      moment(workTask?.dueDate).isValid()
        ? moment(workTask?.dueDate).toISOString()
        : moment().toISOString(),
    );
  }, [workTask]);

  const showIsDoneWorkTask = (): boolean => {
    if (updatedWorkTask?.isDone === workTask?.isDone) {
      return workTask?.isDone;
    }
    if (previewIsDone !== null) {
      return previewIsDone;
    }
    if (updatedWorkTask) {
      return updatedWorkTask?.isDone;
    }
    return workTask?.isDone;
  };
  const onChangeDueDate = (data: FormData) => {
    updateContactNote({
      ...workTask,
      dueDate: data.dueDate,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setPreviewDone(null);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setPreviewDone(null);
      toast("Something went wrong", {
        type: "error",
        pauseOnHover: false,
      });
    }
  }, [isError]);

  return (
    <>
      <div
        className={classNames(
          "grid grid-cols-[1fr_max-content] rounded-[7px] border border-gray-300 bg-base-100 px-4 py-2",
          !isCollapsed && "items-center",
        )}>
        <div
          className={classNames(
            "grid grid-cols-[24px_24px_1fr] gap-4",
            !isCollapsed && "items-center",
          )}>
          <HiOutlineBars3 size={24} className={"text-purple"} />
          <input
            type={"checkbox"}
            className={
              "checkbox border-gray-300 [--chkbg:--b1] [--chkfg:--a] checked:border-gray-300 checked:bg-base-100"
            }
            checked={showIsDoneWorkTask()}
            onChange={handleChangeWorkTaskStatus}
          />
          <div
            className={classNames(
              "grid grid-rows-[repeats(2,max-content)] text-sm",
              workTask?.isDone && "line-through",
            )}>
            <div className={"truncate font-bold"}>{workTask?.name}</div>
            <div
              onClick={() => setIsCollapsed(!isCollapsed)}
              className={classNames(
                "cursor-pointer text-gray-500",
                isCollapsed ? "whitespace-pre-line break-words" : "truncate",
              )}>
              {workTask?.description}
            </div>
          </div>
        </div>
        <div className={"flex flex-col"}>
          <div
            className={
              "grid h-fit grid-cols-[24px_32px_90px_24px] items-center gap-4"
            }>
            <div>
              <Dropdown
                isCloseOnClick
                triggerElement={
                  <Button
                    label={""}
                    colorType={"ghost"}
                    size={"custom"}
                    icon={<ReassignedSvg size={"18px"} />}
                    extraClasses={
                      "text-purple border border-purple btn-xs p-0.5"
                    }
                  />
                }>
                <div
                  className={
                    "rounded-[5px] border border-gray-300 bg-white text-sm shadow-box"
                  }>
                  <div
                    className={
                      "link-primary border-b-[1px] border-b-primary px-4 py-2 text-sm"
                    }>
                    Reassign subtask to:
                  </div>
                  <div className={"max-h-[200px] overflow-y-auto"}>
                    {users?.map((item) => (
                      <div
                        key={item?.id}
                        onClick={() =>
                          reassignWorkTask({
                            name: workTask?.name || "",
                            id: workTask?.id || "",
                            assignedUserId: item?.id,
                            assignedByUserId: user?.id,
                            jobId: workId || "",
                          })
                        }
                        className={
                          "flex cursor-pointer items-center gap-1 px-4 py-2 hover:bg-blue-400 hover:text-white"
                        }>
                        <AvatarPlaceholder
                          size={"super-small"}
                          label={getInitials(
                            item?.userProfile?.firstName || "",
                            item?.userProfile?.lastName || "",
                          )}
                        />
                        <div
                          className={
                            "whitespace-pre-wrap"
                          }>{`${item?.userProfile?.firstName} ${item?.userProfile?.lastName}`}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </Dropdown>
            </div>
            <div className={"cursor-pointer"}>
              <AvatarPlaceholder
                label={getInitials(
                  workTask?.assignedUser?.userProfile?.firstName || "",
                  workTask?.assignedUser?.userProfile?.lastName || "",
                )}
                username={`${workTask?.assignedUser?.userProfile?.firstName} ${workTask?.assignedUser?.userProfile?.lastName}`}
                size={"super-small"}
                isShowUsername
              />
            </div>
            <div className={"flex items-center gap-2"}>
              <div
                className={classNames(
                  "h-2.5 w-2.5 rounded-full",
                  workTask?.isDone ? "bg-green" : "bg-yellow",
                )}
              />
              <div className={"text-sm"}>
                {workTask?.isDone ? "Completed" : "In progress"}
              </div>
            </div>
            <div>
              <Dropdown
                triggerElement={
                  <Button
                    label={""}
                    colorType={"ghost"}
                    size={"custom"}
                    icon={<HiEllipsisVertical size={20} />}
                    extraClasses={"btn-xs p-0.5"}
                  />
                }>
                <div
                  className={
                    "rounded-[5px] border border-gray-300 bg-white text-sm shadow-box"
                  }>
                  <div
                    onClick={() => setIsOpenUpdate(true)}
                    className={
                      "cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
                    }>
                    Edit
                  </div>
                  <div
                    onClick={() => deleteWorkTask(workTask)}
                    className={
                      "cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
                    }>
                    Delete
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>
          <div
            className={
              "mr-5 flex w-[100%] items-center justify-end gap-2 text-end text-xs font-semibold text-gray-800"
            }>
            Due date:
            <div className={"w-[40%]"}>
              <Controller
                name={"dueDate"}
                control={control}
                render={({ field: { value, onChange } }) => {
                  const handleDateChange = (date: Date | null) => {
                    if (date instanceof Date && !isNaN(date.getTime())) {
                      const isoDate = moment(date).toISOString();
                      onChange(isoDate);

                      const formData: FormData = {
                        ...getValues(),
                        dueDate: isoDate,
                      };

                      onChangeDueDate(formData);
                    }
                  };
                  return (
                    <StyledDatePicker
                      value={value ? moment(value).toDate() : new Date()}
                      onChange={handleDateChange}
                      minDate={
                        moment(getValues("startDate")).isValid()
                          ? moment(getValues("startDate")).toDate()
                          : new Date()
                      }
                      styleType={"ghost"}
                      fontSize={"12px"}
                      fontWeight={"500"}
                      color={"rgb(31, 41, 55)"}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {isOpenUpdate && (
        <WorkTaskModal
          isActive={isOpenUpdate}
          onClose={() => setIsOpenUpdate(false)}
          defaultValues={workTask}
        />
      )}
    </>
  );
};
