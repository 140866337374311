import * as Xstate from "@xstate/react";
import React, { FC, useEffect, useState } from "react";
import { HiMiniAdjustmentsHorizontal } from "react-icons/hi2";
import { useSearchParams } from "react-router-dom";

import { GlobalContext } from "../../../app/stateMachines/GlobalContext";
import Button from "../../../components/button/Button";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import {
  useExportWorksQuery,
  useGetArchivedWorksQuery,
  useGetMyWorkStatsQuery,
  useGetWorksQuery,
} from "../../../slices/WorkSlice";
import { MyWorksFilters } from "../components/MyWorksFilters";
import { WorkTableSort } from "../components/types/work-table";
import { WorkList } from "../components/WorkList";
import { WorksStats } from "../components/WorksStats";
import { AddWorkModal } from "../modals/AddWork.modal";
import { FilterWorksModal } from "../modals/FilterWorks.modal";
import { Filters } from "./AllWorks";
import { ExcelExport } from "../../../utils/ExportData";
import moment from "moment/moment";
import { useAppSelector } from "../../../redux/redux";

export const ArchivedWorks: FC = () => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState<Filters>({
    customerIds: [],
    workName: [],
    userIds: [],
    workStatuses: [],
    worktypeIds: [],

    startDates: [],
    dueDates: [],
    fromDate: null,
    toDate: null,
  });
  const { user } = useAppSelector((state) => state.appReducer);

  const page = +(searchParams.get("page") || 1);
  const sortBy = searchParams.get("sortBy") as WorkTableSort;
  const sortOrder = searchParams.get("sortOrder") as "asc" | "desc";

  const { data: works, isFetching: isLoadingWorks } = useGetArchivedWorksQuery({
    orgId: getLinkedFirm()?.orgId || "",
    userId: user.id,
  });
  const {
    data: workStats,
    isLoading,
    isError,
  } = useGetMyWorkStatsQuery({
    orgId: getLinkedFirm()?.orgId || "",
    userRelayId: userInfoByEmail?.id || "",
  });

  const [isAddWork, setIsAddWork] = useState(false);
  const [isOpenFilters, setIsOpenFilters] = useState(false);

  useEffect(() => {
    setSearchParams({
      page: page?.toString() || "1",
      sortBy: sortBy?.toString() || WorkTableSort.WorkName,
      sortOrder: sortOrder?.toString() || "asc",
    });
  }, []);

  if (isLoading) {
    return <LottieLoading />;
  }

  if (isError) {
    return null;
  }

  return (
    <>
      <div className={"grid h-full w-full grid-rows-[max-content_1fr] gap-6 "}>
        <WorksStats
          allWorks={works?.length || 0}
          completedWorks={works?.[0].completedRecords || 0}
        />
        <WorkList works={works || []} isLoading={isLoadingWorks} hasPages>
          <div
            className={
              "grid grid-cols-[1fr_max-content] items-start gap-2 px-8 pt-6 xl:items-end"
            }>
            <MyWorksFilters setFilters={setFilters} filters={filters} />
            <div
              className={
                "grid grid-cols-[repeat(3,max-content)] gap-2 pt-6 xl:pt-0"
              }>
              <div className={"flex items-center"}>
                <ExcelExport
                  data={
                    works?.map((work) => ({
                      "Work Name": work.workName,
                      Contact: work.customerName,
                      Status: work.jobStatus,
                      "Start Date": moment(work.startDate).format("YYYY-MM-DD"),
                      "Due Date": moment(work.dueDate).format("YYYY-MM-DD"),
                      Progress:
                        (work.completedTaskCounts / work.taskCounts) * 100 || 0,
                      Repeats: work.repeats,
                      "Work Type": work.workType,
                      Assignee: work.userFirstName + " " + work.userLastName,
                    })) || []
                  }
                  fileName={"Archived Works"}
                />
              </div>
              <Button
                label={""}
                icon={
                  <HiMiniAdjustmentsHorizontal
                    size={24}
                    className={"text-purple"}
                  />
                }
                onClick={() => setIsOpenFilters(true)}
                colorType={"ghost"}
              />
              <Button
                buttonType={"button"}
                label={"Add work"}
                onClick={() => setIsAddWork(true)}
              />
            </div>
          </div>
        </WorkList>
      </div>
      {isAddWork && (
        <AddWorkModal isOpen={isAddWork} onClose={() => setIsAddWork(false)} />
      )}
      {isOpenFilters && (
        <FilterWorksModal
          isOpen={isOpenFilters}
          onClose={() => setIsOpenFilters(false)}
          setFilters={setFilters}
          filters={filters}
        />
      )}
    </>
  );
};
