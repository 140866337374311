import { Datepicker } from "baseui/datepicker";
import { FC } from "react";

type Props = {
  value: Date;
  minDate?: Date;
  maxDate?: Date;
  onChange: (date: Date) => void;
  mountNode?: string;
  styleType?: "default" | "ghost";
  fontSize?: string;
  fontStyle?: string;
  fontWeight?: string;
  color?: string;
};

export const StyledDatePicker: FC<Props> = ({
  value,
  onChange,
  mountNode,
  minDate,
  maxDate,
  styleType = "default",
  fontSize,
  fontStyle,
  fontWeight,
  color,
}) => {
  return (
    <Datepicker
      value={value}
      minDate={minDate || null}
      maxDate={maxDate || null}
      overrides={{
        Input: {
          props: {
            overrides: {
              Input: {
                props: {
                  mountNode: mountNode
                    ? document.getElementById(mountNode)
                    : null,
                },
                style: () =>
                  styleType === "ghost"
                    ? {
                        backgroundColor: "#FFFFFF",
                        paddingBottom: "0",
                        paddingTop: "0",
                        paddingRight: "0",
                        paddingLeft: "0",
                        fontSize: fontSize ? fontSize : "14px",
                        fontWeight: fontWeight ? fontWeight : "500",
                        width: "100px",
                        cursor: "pointer",
                        fontStyle: fontStyle ? fontStyle : "base",
                        color: color ? color : "black",
                      }
                    : {
                        backgroundColor: "#FFFFFF",
                      },
              },
              Root: {
                style: () =>
                  styleType === "ghost"
                    ? {
                        borderBottomColor: "transparent",
                        borderTopColor: "transparent",
                        borderRightColor: "transparent",
                        borderLeftColor: "transparent",
                        borderBottomWidth: "0px",
                        borderTopWidth: "0px",
                        borderRightWidth: "0px",
                        borderLeftWidth: "0px",
                      }
                    : {
                        borderTopWidth: "1px",
                        borderRightWidth: "1px",
                        borderBottomWidth: "1px",
                        borderLeftWidth: "1px",
                        borderTopColor: "#D4D6D9",
                        borderRightColor: "#D4D6D9",
                        borderBottomColor: "#D4D6D9",
                        borderLeftColor: "#D4D6D9",
                      },
              },
            },
          },
        },
        MonthYearSelectPopover: {
          props: {
            overrides: {
              Body: {
                style: { zIndex: 9999 },
              },
            },
          },
        },
        Popover: {
          props: {
            overrides: {
              Body: {
                style: {
                  zIndex: 9999,
                },
              },
            },
          },
        },
      }}
      onChange={({ date }) => {
        if (date instanceof Date) {
          onChange(date);
        }
      }}
    />
  );
};
