import * as Xstate from "@xstate/react";
import React, { FC, useState } from "react";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import Button from "../../components/button/Button";
import { OutlineButton } from "../../components/button/OutlineButton";
import { useAppDispatch, useAppSelector } from "../../redux/redux";
import { timerSlice, updateTimer } from "../../services/TimerService";
import { SelectOptionType } from "../../types/types";
import { getLinkedFirm } from "../../sessionStorage/sessionStorage";
import { DATES } from "../../app/commonOps/CommonDateOps";
import { useGetSearchCustomersQuery } from "../../slices/ContactsSlice";
import { useDebounce } from "usehooks-ts";
import CustomSelect from "../../components/select/Select";
import { getInitialsFromFullName } from "../../constants/constants";
import { useGetSearchCustomersWorksQuery } from "../../slices/WorkSlice";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  defaultContact?: SelectOptionType;
};

const StartTimer: FC<Props> = ({ isOpen, closeModal, defaultContact }) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.appReducer);

  const { setStatusTimer } = timerSlice.actions;
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const [work, setWork] = useState<SelectOptionType | null>(null);
  const [searchContacts, setSearchContacts] = useState("");
  const [contact, setContact] = useState<SelectOptionType | null>(null);

  const searchContactsDebounce = useDebounce(searchContacts, 300);

  const { data: works, isLoading: isLoadingWorks } =
    useGetSearchCustomersWorksQuery({
      customerId: contact?.value || "",
      orgId: getLinkedFirm()?.orgId || "",
    });

  const { data: contacts, isLoading: isLoadingContacts } =
    useGetSearchCustomersQuery({
      query: searchContactsDebounce || "a",
      orgId: getLinkedFirm()?.orgId || "",
      userId: user.id,
    });

  const contactsOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (contacts) {
      contacts.forEach((contact) => {
        options.push({
          value: contact?.id,
          label: `${contact.name}`,
          avatar: getInitialsFromFullName(contact?.name),
          avatarColor: contact.businessContactId ? "purple" : "blue",
        });
      });
    }

    return options;
  };

  const onSubmit = async () => {
    if (!contact || !work) {
      return;
    }
    if (work && contact.value) {
      dispatch(
        updateTimer({
          duration: 0,
          workId: work?.value || "",
          notes: "",
          timerStatus: "started",
          assignedUserRelayId: userInfoByEmail!.id,
          contactId: work?.businessContactId ? null : work?.contactId,
          businessContactId: work?.businessContactId
            ? work?.businessContactId
            : null,
          date: DATES.today(),
        }),
      );
      dispatch(
        setStatusTimer({
          timerStatus: "started",
          workId: work?.value,
          contactId: work?.businessContactId ? null : work?.contactId,
          businessContactId: work?.businessContactId
            ? work?.businessContactId
            : null,
          work: { name: work!.label },
        }),
      );
      closeModal();
    }
  };

  return (
    <div
      onClick={closeModal}
      id={"my-popup"}
      className={
        "fixed left-0 top-0 z-[4] flex h-screen w-screen items-center justify-center bg-gray-500/20"
      }>
      <div
        className={
          "max-h-[750px] w-[480px] overflow-hidden rounded-2xl bg-white p-6 "
        }
        onClick={(event) => event.stopPropagation()}>
        <div>
          <div className={""}>
            <div className={"mb-10 w-full text-center text-[18px] font-bold"}>
              Start Timer
            </div>
            <div className={"flex h-[350px] flex-col justify-between "}>
              <div>
                <div className={"relative z-[1] mb-4"}>
                  <div className={"label-text mb-2"}>Contact</div>
                  <div>
                    <CustomSelect
                      options={contactsOptions()}
                      value={contact}
                      onChange={(newValue) => {
                        const newContact = newValue as SelectOptionType;
                        setContact(newContact);
                      }}
                      inputValue={searchContacts}
                      onChangeInput={setSearchContacts}
                      fullWidth
                      withAvatar
                      isLoading={isLoadingContacts}
                    />
                  </div>
                </div>
                <div className={"mb-4"}>
                  <div className={"label-text mb-2"}>Work</div>
                  <CustomSelect
                    options={works || []}
                    value={work}
                    onChange={(newValue) => {
                      const value = newValue as SelectOptionType;
                      setWork(value);
                    }}
                    fullWidth
                    isLoading={isLoadingWorks}
                    placeholder={"Search..."}
                  />
                </div>
              </div>
              <div className={"flex justify-end"}>
                <div className={"flex space-x-[10px]"}>
                  <Button
                    buttonType={"button"}
                    label={"Start"}
                    onClick={onSubmit}
                    disabled={!contact || !work}
                    extraClasses={"normal-case"}
                  />
                  <OutlineButton
                    buttonType={"button"}
                    label={"Cancel"}
                    colorType={"neutral"}
                    onClick={closeModal}
                    extraClasses={"normal-case"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartTimer;
