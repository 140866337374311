import classNames from "classnames";
import React, { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import Button from "../../../components/button/Button";
import { WorkTaskModel } from "../../../factories/work/model/work-task.model";
import { useAppSelector } from "../../../redux/redux";
import {
  useAddWorkTaskMutation,
  useUpdateWorkTaskMutation,
} from "../../../slices/WorkTasksSlice";
import { StyledDatePicker } from "../../../components/datepicker/datepicker";
import moment from "moment/moment";
import { getInitials } from "../../../constants/constants";

type Props = {
  isActive: boolean;
  onClose: () => void;
  defaultValues?: WorkTaskModel;
  onDateChange?: (newDate: string) => void;
};

type FormData = {
  name: string;
  description: string;
  dueDate: string;
  startDate: string;
};

export const WorkTaskModal: FC<Props> = ({
  isActive,
  onClose,
  defaultValues,
  onDateChange,
}) => {
  const { workId } = useParams();
  const { user } = useAppSelector((state) => state.appReducer);

  const [addContactNote, { isLoading: isLoadingAdd, isSuccess: isSuccessAdd }] =
    useAddWorkTaskMutation();
  const [
    updateContactNote,
    { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate },
  ] = useUpdateWorkTaskMutation();

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      startDate: moment().toISOString(),
      dueDate: moment(defaultValues?.dueDate).isValid()
        ? moment(defaultValues?.dueDate).toISOString()
        : moment().toISOString(),
      name: defaultValues?.name || "",
      description: defaultValues?.description || "",
    },
  });

  const onSubmit = (data: FormData) => {
    if (data.name && !isLoadingAdd && !isLoadingUpdate) {
      if (defaultValues) {
        updateContactNote({
          ...defaultValues,
          name: data.name,
          description: data.description,
          jobIdBase64: workId || "",
          dueDate: data.dueDate,
        });
      } else {
        addContactNote({
          name: data.name,
          description: data.description,
          jobIdBase64: workId || "",
          assignedUserId: user?.id || "",
          dueDate: data.dueDate,
        });
      }
    }
  };
  useEffect(() => {
    const dueDateValue = getValues("dueDate");
    if (onDateChange) {
      onDateChange(dueDateValue);
    }
  }, [getValues("dueDate"), onDateChange]);
  useEffect(() => {
    if (isSuccessAdd || isSuccessUpdate) {
      onClose();
    }
  }, [isSuccessAdd, isSuccessUpdate]);

  return (
    <div
      className={classNames("modal", isActive ? "modal-open" : "modal-close")}>
      <div className={"modal-box p-0"}>
        <div
          className={
            "border-b border-gray-300 px-5 py-4 text-center text-lg font-bold"
          }>
          {defaultValues ? "Update" : "Add"} subtask
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className={"px-5 py-4"}>
          <div className={"mb-4"}>
            <input
              type={"text"}
              {...register("name", {
                required: "This field is required",
                maxLength: {
                  value: 100,
                  message: "Task name shouldn't be more than 100 characters",
                },
              })}
              className={"input input-bordered w-full"}
              placeholder={"Subtask name"}
            />
            {errors?.name?.message && (
              <div className={"mt-2 text-sm font-bold text-error"}>
                {errors?.name?.message}
              </div>
            )}
          </div>
          <div>
            <textarea
              {...register("description")}
              className={"input input-bordered h-[200px] w-full"}
              placeholder={"Description"}
            />
          </div>
          <div className={"flex items-center gap-4"}>
            Due date:
            <div className={"w-[25%]"}>
              <Controller
                name={"dueDate"}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <StyledDatePicker
                    value={moment(value).toDate()}
                    onChange={(date) => onChange(moment(date).toISOString())}
                    minDate={moment(getValues("startDate")).toDate()}
                  />
                )}
              />
            </div>
          </div>
          <div className={"mt-4 flex justify-end gap-4"}>
            <Button
              label={defaultValues ? "Update" : "Add"}
              buttonType={"submit"}
              disabled={isLoadingAdd || isLoadingUpdate}
              isLoading={isLoadingAdd || isLoadingUpdate}
            />
            <Button label={"Cancel"} onClick={onClose} colorType={"outline"} />
          </div>
        </form>
      </div>
    </div>
  );
};
